<template>
	<div class="card mb-0">
      <div class="card-buttons-top">
         <div class="card-body">
            <div id="buttons" class="w-100 text-center pb-5">
               <div class="badge bg-theme cursor-pointer mb-2 me-2" @click="abrir">
                  <i class="fal fa-folder font-11 me-1"></i> {{ $t('script.abrir') }}
               </div>
               <div class="badge bg-theme cursor-pointer mb-2 me-2" @click="avaliarProduto">
                  <i class="fal fa-star font-11 me-1"></i> {{ $t('script.avaliar') }}
               </div>
               <div class="badge bg-secondary cursor-pointer mb-2" @click="reportarLink">
                  <i class="fal fa-unlink font-11 me-1"></i> {{ $t('script.reportar') }}
               </div>
            </div>
				<h6 class="limitador-2 font-20">{{ script.nome }}</h6>
            <div class="row card-info-bottom align-items-center">
               <div class="col-12 font-12">
                  <i class="fa-star" :class="parseInt(script.notaTotal / script.notaQtd) >= 1 ? 'fas color-theme' : 'fal'"></i>
                  <i class="fa-star" :class="parseInt(script.notaTotal / script.notaQtd) >= 2 ? 'fas color-theme' : 'fal'"></i>
                  <i class="fa-star" :class="parseInt(script.notaTotal / script.notaQtd) >= 3 ? 'fas color-theme' : 'fal'"></i>
                  <i class="fa-star" :class="parseInt(script.notaTotal / script.notaQtd) >= 4 ? 'fas color-theme' : 'fal'"></i>
                  <i class="fa-star" :class="parseInt(script.notaTotal / script.notaQtd) >= 5 ? 'fas color-theme' : 'fal'"></i>
                  <span class="ms-4" v-if="script.notaPessoal"><i class="fas fa-star color-theme me-2"></i>{{ script.notaPessoal }}</span>
               </div>
               <div class="col-12 mt-3">
                  <p class="limitador-3"><i class="fas fa-info-circle font-12 color-theme me-1"></i> {{ script.descricao != null ? script.descricao : 'Sem descrição' }}</p>
               </div>
            </div>
			</div>
      </div>
      <img :src="script.nomeArquivo == null ? '' : script.nomeArquivo" class="card-img-top" alt="noticia" @error="imageError">
   </div>
</template>

<script>

import Swal from 'sweetalert2'
import { mapState } from 'vuex'

export default {
	name: 'Script',
	props: ['script', 'index'],
	computed: {
		...mapState({
			urlRest: state => state.urlRest
		})
	},
   methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
		abrir : function () {
         this.$emit('abrir', this.script)
      },
      avaliarProduto : function () {
         Swal.fire({
            icon: 'question',
            title: this.$t('script.avaliarDesc'),
            input: 'range',
            inputAttributes: {
               min: 1,
               max: 5,
               step: 1
            },
            inputValue: 3,
            showCancelButton: true,
            cancelButtonText: this.$t('script.botaoCancelar'),
            confirmButtonText: this.$t('script.botaoConfirmar')

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
      
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicosAdmin/avaliarProduto',
                  headers: { 
                     'Content-Type': 'application/json'
                  },
                  data: {
                     id: this.script.id, 
                     tipo: 'Script', 
                     nota: result.value
                  }
               }).then(response => {
                  this.script.notaQtd = response.data.notaQtd
                  this.script.notaTotal = response.data.notaTotal
                  this.script.notaPessoal = parseInt(result.value)
      
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      },
      reportarLink : function () {
         Swal.fire({
            icon: 'warning',
            title: this.$t('script.reportarDesc'),
            confirmButtonText: this.$t('script.botaoConfirmar'),
            showCancelButton: true,
            cancelButtonText: this.$t('script.botaoCancelar')

         }).then((result) => {
            if (result.isConfirmed) {
               this.$store.dispatch('alternarTelaCarregamento', true)
      
               this.$axios({
                  method: 'post',
                  url: this.urlRest +'servicosAdmin/reportarLinkScript',
                  params: {
                     id: this.script.id
                  }
               }).then(() => {
                  this.$toast.fire({
                     icon: 'success',
                     title: this.$t('script.reportarSucesso')
                  });
               }).catch((error) => {
                  if (error.response != undefined) {
                     if (error.response.status == 408) {
                        this.$store.dispatch('deslogar')

                        this.$toast.fire({
                           icon: 'warning',
                           title: this.$t('sessaoExpirada')
                        });
                     } else {
                        this.$toast.fire({
                           icon: 'error',
                           title: 'Erro: ' + error.response.status
                        })
                     }
                  } else {
                     this.$toast.fire({
                        icon: 'error',
                        title: error
                     });
                  }
               }).finally(() => {
                  this.$store.dispatch('alternarTelaCarregamento', false)
               });
            }
         })
      }
   }
}

</script>

<style scoped>

.card-buttons-top {
   display: flex;
	flex-wrap: wrap;
   justify-content: center;
   align-items: end;
   position: absolute;
   top: 0;
   bottom: 0;
   left: 0;
   right: 0;
   background: #0006;
   transition: 0.3s;
}

.card-buttons-top:hover {
	background: rgba(0, 0, 0, 0.658);
}

.card-buttons-top:hover #buttons {
	display: block !important;
}

.card-buttons-top #buttons {
	display: none !important;
}

.card-info-bottom * {
   filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

.card-info-bottom a {
   color: #ddd;
   cursor: pointer;
}

.card-info-bottom a:hover {
   text-decoration: underline !important;
}

h6 {
   color: #ddd;
   margin-bottom: 4px !important;
   font-weight: 700 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
}

p {
	color: #eee;
   font-size: 13px !important;
   margin-bottom: 0 !important;
	filter: drop-shadow(0px 0px 2px rgb(0, 0, 0));
	line-height: 1.4;
}

img {
	aspect-ratio: 3/4;
	object-fit: cover;
}

</style>