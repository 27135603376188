<template>
   <div class="row">
      <div class="col-12" v-if="pesquisa.resultado.length > 0">
         <div class="cards-2">
            <scripts v-for="(script, index) in pesquisa.resultado" :key="index" :script="script" :index="index" @abrir="abrirScript($event)" />
         </div>
      </div>
      <div class="col-12 my-5 text-center" v-else>{{ $t('admMeusScripts.semResultados') }}</div>

      <!-- modalScript -->
      <div class="modal fade" id="modalScript" aria-labelledby="modalScriptLabel" aria-hidden="true">
         <div class="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalScriptLabel">Script</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
                  <div class="card mb-2">
                     <div class="card-body">
                        <div class="row">
                           <div class="wpx-250 align-self-center mx-auto mx-md-0 mb-3 mb-md-0">
                              <img width="100%" class="rounded" :src="script.nomeArquivo == null ? '' : script.nomeArquivo" alt="banner" @error="imageError">
                           </div>
                           <div class="col-md py-2">
                              <h4>{{ script.nome }}</h4>
                              <p><i class="far fa-info-circle font-12 color-theme me-1"></i> {{ script.descricao != null ? script.descricao : 'Sem descrição' }}</p>
                              <a :href="script.linkDocumentacao" target="_blank" v-if="script.linkDocumentacao != null && script.linkDocumentacao != ''">
                                 <h6 class="weight-400 mb-3"><i class="fas fa-file font-13 color-theme me-1"></i> {{ $t('admMeusScripts.documentacao') }}</h6>
                              </a>
                              <a :href="script.linkDriver" target="_blank" v-if="script.linkDriver != null && script.linkDriver != ''">
                                 <h6 class="weight-400 mb-0"><i class="fab fa-google-drive font-13 color-theme me-1"></i> Drive</h6>
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-2">
                     <div class="card-body">
                        <div class="row">
                           <div class="col-12 mb-3">
                              <label><i class="far fa-turtle color-theme me-1"></i> {{ $t('admMeusScripts.linkSvn') }}</label>
                              <input disabled type="text" class="form-control" maxlength="200" v-model="script.linkSvn" />
                           </div>
                           <div class="col-6">
                              <label><i class="far fa-user color-theme me-1"></i> {{ $t('admMeusScripts.loginSvn') }}</label>
                              <input disabled type="text" class="form-control" maxlength="200" v-model="script.loginSvn" />
                           </div>
                           <div class="col-6">
                              <label><i class="far fa-lock color-theme me-1"></i> {{ $t('admMeusScripts.senhaSvn') }}</label>
                              <input disabled :type="showPassword ? 'text' : 'password'" class="form-control" maxlength="200" v-model="script.senhaSvn" />
                              <span @click="showPassword = !showPassword;" class="toggle-password">
                                 <i v-if="showPassword" class="fas fa-eye"></i>
                                 <i v-else class="fas fa-eye-slash"></i>
                              </span>  
                           </div>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-2" v-if="script.link">
                     <div class="card-body">
                        <h4 class="limitador font-18 mb-3">{{ $t('admMeusScripts.video') }}</h4>
                        <div class="ratio ratio-16x9">
                           <iframe :src="script.link" title="Video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media;" 
                              allowfullscreen v-if="String(script.link).startsWith('https://www.youtube.com/embed/') || String(script.link).startsWith('https://iframe.mediadelivery.net/embed/')">
                           </iframe>
                        </div>
                     </div>
                  </div>

                  <div class="card mb-2" v-if="script.listFotos != null && script.listFotos.length > 0">
                     <div class="card-body">
                        <h4 class="limitador font-18 mb-3">{{ $t('admMeusScripts.imagens') }}</h4>

                        <div class="cards-5">
                           <div class="imageContainer" v-for="(foto, index) in script.listFotos" :key="index">
                              <img class="rounded cursoVinc" :src="foto.urlImage == null ? '' : foto.urlImage" @error="imageError">
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import scripts from '@/components/admin/meusscripts/Script.vue'

export default {
	name: 'MeusScripts',
	data : function() {
		return {
			pesquisa: {'nome': '', 'resultado': []},
         script: {
            "id": null, "nome": null, "descricao": null, "link": null, "nomeArquivo": null, "valor": null, "linkDriver": null, "linkSvn": null, 
            "linkDocumentacao": null, "loginSvn": null, "senhaSvn": null, "vinculo": false, "listFotos": []
         },
         showPassword: false
		}
	},
	computed: {
		...mapState({
			dadosUsuario:  state => state.dadosUsuario,
         urlRest: state => state.urlRest
		})
	},
   components: {
      scripts
   },
	methods: {
      imageError : function (e) {
         this.$store.dispatch('imageError', e)
      },
      searchMeusProdutos : function () {
         this.$store.dispatch('alternarTelaCarregamento', true)
         this.pesquisa.resultado = [];
         
         this.$axios({
            method: 'get',
            url: this.urlRest +'servicos/searchMeusProdutos',
            params: {
               id: this.dadosUsuario.id
            }
         }).then(response => {
				this.pesquisa.resultado = response.data;

         }).catch((error) => {
            if (error.response != undefined) {
               if (error.response.status == 408) {
                  this.$store.dispatch('deslogar')

                  this.$toast.fire({
                     icon: 'warning',
                     title: this.$t('sessaoExpirada')
                  });
               } else {
                  this.$toast.fire({
                     icon: 'error',
                     title: 'Erro: ' + error.response.status
                  });
               }
            } else {
               this.$toast.fire({
                  icon: 'error',
                  title: error
               });
            }
         }).finally(() => {
            this.$store.dispatch('alternarTelaCarregamento', false)
         });
      },
      abrirScript : function (script) {
         this.script = JSON.parse(JSON.stringify(script));
         $('#modalScript').modal('show')
      }
	},
	mounted() {
      this.searchMeusProdutos()
	}
}

</script>

<style scoped>

img[alt="banner"] {
	aspect-ratio: 3/4;
	object-fit: cover;
}

.toggle-password {
   position: absolute;
   top: 80%;
   transform: translateY(-50%);
   right: 25px;
   cursor: pointer;
}

</style>